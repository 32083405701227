export const primaryGradientBackgroundStyle = {
  color: "white",
  background: "linear-gradient(to left, rgb(0 126 255), rgb(2, 214, 242))",
  "&:hover": {
    background: "linear-gradient(to left, rgb(2, 214, 242), rgb(0 126 255))",
  },
};

export const splitButtonBackgroundStyle = {
  color: "white",
  background: "linear-gradient(to left, rgb(53, 133, 254), rgb(52, 218, 255))",
  "&:hover": {
    background: "linear-gradient(to left, rgb(0, 92, 229), rgb(0, 200, 243))",
  },
};
