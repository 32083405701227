import { LoadingButton as ButtonBase } from "@mui/lab";
import type { ButtonProps as ButtonBaseProps } from "@mui/material";
import { Box, Divider, useTheme } from "@mui/material";
import { type ElementType, forwardRef, type ReactNode } from "react";

import { splitButtonBackgroundStyle } from "~/constants/styles";

type ButtonSize = "sm" | "md" | "lg" | "xl";
type ButtonVariant = "gradient" | "contained" | "outlined" | "subtle" | "danger" | "text";

type ButtonProps<T extends ElementType> = Omit<ButtonBaseProps<T>, "size" | "variant"> & {
  size?: ButtonSize;
  variant?: ButtonVariant;
  loading?: boolean;
  loadingPosition?: "center" | "start" | "end";
  disabled?: boolean;
  children: ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps<ElementType>>(
  (
    {
      size = "md",
      variant = "contained",
      children,
      loading,
      loadingPosition = "center",
      disabled,
      ...restProps
    },
    ref,
  ) => {
    const isDisabled = disabled || loading;

    return (
      <ButtonBase
        ref={ref}
        disabled={isDisabled}
        loading={loading}
        {...variantMapProps[variant]}
        {...sizeMapProps[size]}
        {...restProps}
      >
        <Box
          sx={{
            "&:first-of-type": {
              pl: "4px",
            },
            "&:last-of-type": {
              pr: "4px",
            },
          }}
        >
          {children}
        </Box>
      </ButtonBase>
    );
  },
);

Button.displayName = "Button";

export function SplitButton<T extends ElementType>({
  size = "md",
  children,
  loading,
  loadingPosition = "center",
  disabled,
  ...restProps
}: ButtonProps<T>) {
  const theme = useTheme();
  return (
    <Button
      size={size}
      loading={loading}
      loadingPosition={loadingPosition}
      disabled={disabled}
      {...restProps}
      sx={{
        ...restProps?.sx,
        ...splitButtonBackgroundStyle,
      }}
    >
      <Box sx={{ display: "flex" }}>
        {children}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            ml: 1.5,
            opacity: 0.5,
            borderColor: theme.palette.neutral[0],
          }}
        ></Divider>
      </Box>
    </Button>
  );
}

const variantMapProps: Record<ButtonVariant, ButtonBaseProps> = {
  gradient: {
    variant: "gradient",
  },
  contained: {
    variant: "contained",
  },
  outlined: {
    variant: "outlined",
  },
  subtle: {
    variant: "contained",
    color: "gray",
  },
  danger: {
    variant: "contained",
    color: "error",
  },
  text: {
    variant: "text",
  },
};

const sizeMapProps: Record<ButtonSize, ButtonBaseProps> = {
  sm: { size: "small" },
  md: { size: "medium" },
  lg: { size: "large" },
  xl: { size: "xLarge" },
};
